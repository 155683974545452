module controllers {
    export module consigment {
        interface IConsignmentCreateScope extends ng.IScope {
            getCurrentEntity

        }

        interface IConsignmentCreateParams extends ng.ui.IStateParamsService {
            consignmentId: number;
            commercialInvoiceId: number;
        }

        export class consignmentCreateSalesOrderCtrl {
            static $inject = [
                "$scope",
                "$rootScope",
                "consignmentService",
                "$timeout",
                "generalService",
                "$state",
                "entityService",
                "shipperService",
                "consigneeService",
                "counterService",
                "consignmentTypeService",
                "$q",
                "bsLoadingOverlayService",
                "routeCodeService",
                "supplierService",
                "customerService",
                "salesOrderService",
                "isInbound",
                "commercialInvoiceService",
                "$stateParams"
            ];

            breadCrumbDesc: string;

            consignmentId: number;
            commercialInvoiceId: number;

            selectedTab: number = 0;
            ShowConsignment: boolean = true;
            ShowSalesOrdersGrid: boolean = false;
            ShowInvoiceLinesGrid: boolean = false;
            NextStepButton: string = "Next";
            EnforceLinkingCItoSO: boolean = false;

            consignmentTypeDefaults: interfaces.consignment.ConsignmentTypeDefaults;
            disableConsignment: boolean = false;
            disableSalesOrders: boolean = true;
            disableInvoiceLines: boolean = true;
            customNumberCheck: boolean = false;
            customInvoiceNumberCheck: boolean = false;

            //Entity Variables
            public ownerEntityId: number;
            entityList: Array<interfaces.applicationcore.IEntity>;

            showGrid: boolean = false;
            IsLoading: boolean = false;

            //Shipper Variables
            shipperModel: interfaces.applicationcore.IDropdownModel;
            shipperList: Array<interfaces.applicationcore.IDropdownModel>;

            //Consignee Variables
            consigneeModel: interfaces.applicationcore.IDropdownModel;
            consigneeList: Array<interfaces.applicationcore.IDropdownModel>;

            customNumber: string;
            invoiceCustomNumber: string;

            //Counter Variables
            consignmentCounterId: number;
            counterTypeDefer: ng.IDeferred<any>;
            consignmentCounterList: Array<interfaces.applicationcore.IDropdownModel>;

            //Consignment Type Variables
            consignmentTypeList: Array<interfaces.applicationcore.IDropdownModel>;
            consignmentTypeDefer: ng.IDeferred<any>;
            TypeId: number;

            //Commercial Invoice Variables
            invoiceCounterId: number;
            invoiceCounterTypeDefer: ng.IDeferred<any>;
            invoiceCounterList: Array<interfaces.applicationcore.IDropdownModel>;

            //Route Code Variables
            loadingCountryID: number;

            routeModel: interfaces.applicationcore.IDropdownModel;
            routeCodeList: Array<interfaces.applicationcore.IDropdownModel>;

            //Consignment Variables
            consignmentSelected: interfaces.applicationcore.IDropdownModel;
            consignmentList: Array<interfaces.applicationcore.IDropdownModel>;

            //Supplier Variables
            supplierModel: interfaces.applicationcore.IDropdownModel;
            supplierList: Array<interfaces.applicationcore.IDropdownModel>;

            //Customer Variables
            customerModel: interfaces.applicationcore.IDropdownModel;
            customerList: Array<interfaces.applicationcore.IDropdownModel>;

            //Variables to call getSalesOrderListForCI() in the Next controller.

            apiSOList: uiGrid.IGridApi;
            selectedSO: uiGrid.IGridRow;

            apiILList: uiGrid.IGridApi;
            selectedIL: uiGrid.IGridRow;

            loadPromises: ng.IPromise<any>[];

            //Get Sales Order Allocation Lines || Allocation Line Submission
            orderIdList: interfaces.logistics.ICISalesOrderSubmit[] = [];

            invoiceLinesList = [];

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            DisableNextButton: boolean = false;

            allowCustomConsignmentCounter : boolean = false;

            constructor(private $scope: IConsignmentCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private shipperService: interfaces.master.IShipperService,
                private consigneeService: interfaces.master.IConsigneeService,
                private counterService: interfaces.master.ICounterService,
                private consignmentTypeService: interfaces.logistics.IConsignmentTypeService,
                private $q: ng.IQService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private routeCodeService: interfaces.master.IRouteCodeService,
                private supplierService: interfaces.master.ISupplierService,
                private customerService: interfaces.master.ICustomerService,
                private salesOrderService: interfaces.sales.ISalesOrderService,
                private isInbound: boolean,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private $stateParams: IConsignmentCreateParams
            ) {

                this.consignmentId = $stateParams.consignmentId;
                this.commercialInvoiceId = $stateParams.commercialInvoiceId;

                if (this.consignmentId > 0 && this.commercialInvoiceId > 0) {
                    this.breadCrumbDesc = "Edit Commercial Invoice";
                }
                else if (this.consignmentId > 0 && this.commercialInvoiceId === 0) {
                    this.breadCrumbDesc = "Add Commercial Invoice";
                }
                else {
                    this.breadCrumbDesc = "Create Export Consignment from Sales Order";
                }

                //Get Entities Data
                this.loadPromises = [];

                //We need to load the Entities and the Current Entity First
                //Current Entity can be either the active Entity the user is working on  --> New Consignment Route
                //OR Current Entity will be the OwnerEntity of the Consignment --> Existing Consignment Route

                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());


                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises).then((data) => {
                        //Now we can load the rest of the Controls, after determining which is the CurrentEntity

                        this.loadControls();
                    });
                });

                $timeout(() => {
                    this.showGrid = true;
                    this.IsLoading = true;
                });
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                if (this.consignmentId === 0 && this.commercialInvoiceId === 0) {
                    //This is a New Consigment / Invoice we creating
                    //default the CurrentEntity and all other controls from there

                    this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                        this.ownerEntityId = result.Id;

                        deferred.resolve(true);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    });
                }
                else {
                    //This is an existing Consignment, so the CurrentEntity must be set 
                    //to the OwnerEntity of the Consignment
                    if (this.consignmentId > 0) {
                        this.consignmentService.GetConsignmentSummary().get({
                            conId: this.consignmentId, comId: this.commercialInvoiceId
                        }, (result: interfaces.consignment.IConsignmentSummary) => {

                            this.ownerEntityId = result.OwnerEntityCode.Id;

                            deferred.resolve(true);

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            deferred.resolve(true);
                        })
                    }
                    else {
                        deferred.resolve(true);
                    }
                }



                return deferred.promise;
            }


            loadControls() {

                this.loadPromises = [];

                this.loadPromises.push(this.getEntityCountry());
                this.loadPromises.push(this.loadRoutes());
                this.loadPromises.push(this.loadConsignmentTypes());
                this.loadPromises.push(this.loadConsignmentCounter());
                this.loadPromises.push(this.loadInvoiceCounter());


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.$q.all(this.loadPromises).then((data) => {
                        //Now we can load the defaults AFTER all the dropdowns have been loaded

                        //This is an existing Consignment but NEW Commercial Invoice
                        //we must load the Consignment Type defaults if TypeId exists      

                        if (this.consignmentId === 0 && this.commercialInvoiceId === 0) {
                            // this.loadConTypeDefaults();
                            var defaultType = _.find(this.consignmentTypeList, function (o) { return o.Selected; });

                            if (defaultType) {
                                this.TypeId = defaultType.Id;
                                this.consignmentTypeChanged();
                            }
                        }

                        //This is an existing Consignment and Commercial Invoice
                        //we must load the Consignment/Invoice Details

                        if (this.consignmentId > 0) {
                            this.loadConsignmentSummary();
                            this.loadConsignmentTypeDefaults();
                        }



                    });
                });


                this.$timeout(() => {
                    this.showGrid = true;
                    this.IsLoading = false;

                });
            }

            //Load Consignment Summary if Existing Consignment

            loadConsignmentSummary() {

                let deferred = this.$q.defer<boolean>();

                if (this.consignmentId > 0) {
                    this.consignmentService.GetConsignmentSummary().get({
                        conId: this.consignmentId, comId: this.commercialInvoiceId
                    }, (result: interfaces.consignment.IConsignmentSummary) => {

                        this.ownerEntityId = result.OwnerEntityCode.Id;
                        this.shipperModel = result.Shipper ? result.Shipper : undefined;
                        this.consigneeModel = result.Consignee ? result.Consignee : undefined;
                        this.routeModel = result.RouteCode ? result.RouteCode : undefined;
                        this.customNumber = result.WaybillNumber;
                        this.supplierModel = result.Supplier ? result.Supplier : undefined;
                        this.customerModel = result.Customer ? result.Customer : undefined;
                        this.TypeId = result.ConsignmentType.Id;
                        this.customNumberCheck = true;
                        this.invoiceCustomNumber = result.InvoiceNumber;
                        this.customInvoiceNumberCheck = true;

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                }
                else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            loadConsignmentTypeDefaults() {

                let deferred = this.$q.defer<boolean>();

                this.consignmentService.GetConsignmentTypeDefaultsByConsignment(this.consignmentId).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.consignmentTypeDefaults = data;
                    deferred.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            updateDefaults() {
                if (this.consignmentTypeDefaults) {

                    this.supplierModel = this.consignmentTypeDefaults.Supplier ? this.consignmentTypeDefaults.Supplier : undefined;
                    this.shipperModel = this.consignmentTypeDefaults.Shipper ? this.consignmentTypeDefaults.Shipper : undefined;
                    this.consigneeModel = this.consignmentTypeDefaults.ShipTo ? this.consignmentTypeDefaults.ShipTo : undefined;
                    this.routeModel = this.consignmentTypeDefaults.Route ? this.consignmentTypeDefaults.Route : undefined;
                    this.consignmentCounterId = this.consignmentTypeDefaults.consignmentCounterId ? this.consignmentTypeDefaults.consignmentCounterId : undefined;

                    if (this.consignmentId===0)
                    {
                        this.customNumber = '';
                        this.customNumberCheck = false;
                    }
                    
                }
            }

            loadConTypeDefaults() {
                return this.consignmentService.getConsignmentTypeDefaults(this.TypeId).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.consignmentTypeDefaults = data;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            //Loading Consignment Types
            loadConsignmentTypes() {
                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.consignmentTypeService.getDropdownList(this.ownerEntityId, this.isInbound).query({}, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.consignmentTypeList = result;

                        angular.forEach(this.consignmentTypeList, (o) => {
                            if (o.Selected) {
                                this.TypeId = o.Id;
                            }
                        });

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.consignmentTypeList = <ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[];
                        deferred.resolve(true);
                    });
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            //Loading Consignment Counters
            loadConsignmentCounter() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: Enum.EnumCounterTypes.Consignment,
                        isInbound: this.isInbound
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.consignmentCounterList = result;

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.consignmentCounterId = o.Id;
                            }
                        });

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            //Loading Consignment Counters
            loadInvoiceCounter() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: Enum.EnumCounterTypes.CommercialInvoice,
                        isInbound: this.isInbound
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.invoiceCounterList = result;

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.invoiceCounterId = o.Id;
                            }
                        });

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            //Loading Entities
            loadEntities() {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }



            getEntityCountry() {
                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {
                    this.entityService.getEntityCountry().get({ entityId: this.ownerEntityId }, (result: interfaces.applicationcore.ICountry) => {
                        this.loadingCountryID = result.Id;
                        deferred.resolve(true);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    });
                }
                else {
                    deferred.resolve(true);
                }
                return deferred.promise;
            }

            //Loading the Shippers
            loadShippers(searchText: string) {

                if (this.ownerEntityId) {
                    return this.shipperService.getShipperDropdown(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading the Consignees
            loadConsignees(searchText: string) {

                if (this.ownerEntityId) {
                    return this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            customCounterChanged() {
                this.customNumber = '';
            }

            customInvoiceCounterChanged() {
                this.invoiceCustomNumber = '';
            }

            //Reload everything for the new Entity
            ownerEntityChanged() {
                this.consigneeList = [];
                this.consigneeModel = undefined;

                this.supplierList = [];
                this.supplierModel = undefined;

                this.customerList = [];
                this.customerModel = undefined;

                this.shipperModel = undefined;
                this.TypeId = undefined;
                this.consignmentCounterId = undefined;

                this.shipperList = undefined;
                this.consignmentCounterList = undefined;


                this.routeModel = undefined;
                this.routeCodeList = undefined;

                this.loadControls();
            }

            consigneeChanged(model: interfaces.applicationcore.IDropdownModel) {

                //Always try and get Route default from Consignee
                this.loadConsigneeDefaults(model.Id);
            }

            loadConsigneeDefaults(consigneeId: number) {
                return this.consigneeService.getConsigneeDefaults(consigneeId).get({

                }, (data: interfaces.master.IConsigneeDefaults) => {

                    if (data.Route) {
                        this.routeModel = data.Route;
                    }
                    if (data.Customer) {
                        this.customerModel = data.Customer;
                    }
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            consignmentTypeChanged() {
                return this.consignmentService.getConsignmentTypeDefaults(this.TypeId).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.consignmentTypeDefaults = data;
                    this.updateDefaults();

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            //Loading Routes
            loadRoutes() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {
                    this.routeCodeService.getDropdownListAll(this.ownerEntityId, this.isInbound).query({}, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        this.routeCodeList = result;
                        deferred.resolve(true);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        deferred.resolve(true);
                    });
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            //Load Suppliers
            getSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Load Customers
            getCustomers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.customerService.getDropdownList(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Build UIGrid
            gSOList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ui-sref="auth.ConsignmentExports.Update({ conId: row.entity.Id })" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: false
                },
                {
                    name: "Number",
                    displayName: "Order Number",
                    field: "Number",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`

                },
                {
                    name: "BuyersReference",
                    displayName: "Buyers Reference",
                    field: "BuyersReference",
                    width: 180,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "SupplierName",
                    displayName: "Supplier Name",
                    field: "SupplierName",
                    width: 180,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "RouteCode",
                    displayName: "Route Code",
                    field: "RouteCode",
                    width: 120,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "TransportModeDescription",
                    displayName: "Transport Mode",
                    field: "TransportModeDescription",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "CountryOfOriginDescription",
                    displayName: "Country Of Origin",
                    field: "CountryOfOriginDescription",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "CountryOfDestinationDescription",
                    displayName: "Country Of Destination",
                    field: "CountryOfDestinationDescription",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "EstimatedTimeofDeparture",
                    displayName: "Estimated Time of Departure",
                    field: "EstimatedTimeofDeparture",
                    width: 200,
                    cellFilter: "date:'yyyy/MM/dd'",
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "EstimatedTimeofArrival",
                    displayName: "Estimated Time of Arrival",
                    field: "EstimatedTimeofArrival",
                    cellFilter: "date:'yyyy/MM/dd'",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "TotalAvailableQuantity",
                    displayName: "Total Available Quantity",
                    field: "TotalAvailableQuantity",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }]
            };

            gvwILList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApiLI(gridApi);

                    this.apiILList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiILList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ui-sref="auth.ConsignmentExports.Update({ conId: row.entity.Id })" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: false
                },
                {
                    name: "OrderNumber",
                    displayName: "Order Number",
                    field: "OrderNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "LineSequenceNumber",
                    displayName: "#",
                    field: "LineSequenceNumber",
                    width: 30,
                    cellClass: 'text-right',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "SkuCode",
                    displayName: "Sku Code",
                    field: "SkuCode",
                    width: 120,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "SkuDescription",
                    displayName: "Sku Description",
                    field: "SkuDescription",
                    width: 280,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "ReferenceNumber",
                    displayName: "Reference Number",
                    field: "ReferenceNumber",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "AvailableQuantity",
                    displayName: "Available Quantity",
                    field: "AvailableQuantity",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 5',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "OrderUnitOfMeasureCode",
                    displayName: "Order Unit Of MeasureCode",
                    field: "OrderUnitOfMeasureCode",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "Price",
                    displayName: "Order Price",
                    field: "Price",
                    width: 120,
                    cellClass: 'text-right',
                    cellFilter: "number: 5",
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "CurrencyCode",
                    displayName: "Currency Code",
                    field: "CurrencyCode",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "InvoiceQuantity",
                    displayName: "Order Quantity",
                    field: "InvoiceQuantity",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 5',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                    </p>
                                </form>`,
                }]
            };

            //Register UIGrid
            registerGridApi(gridApi: uiGrid.IGridApi) {

                this.apiSOList = gridApi;
                this.apiSOList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selectedSO = selected;

                    this.DisableNextButton = false;
                    this.NextStepButton = "Next";
                    if (this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                        this.DisableNextButton = true;
                    }

                    if (!this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                        this.DisableNextButton = false;
                        this.NextStepButton = "Submit";
                    }

                });

                this.$timeout(() => {
                    if (this.selectedSO)
                        this.apiSOList.selection.selectRow(this.selectedSO.entity);
                });
            }

            registerGridApiLI(gridApi: uiGrid.IGridApi) {
                this.apiILList = gridApi;
                this.apiILList.selection.on.rowSelectionChanged(this.$scope, (selected) => { this.selectedIL = selected; });

                this.$timeout(() => {
                    if (this.selectedIL)
                        this.apiILList.selection.selectRow(this.selectedIL.entity);
                });
            }

            LoadSO() {

                this.disableInvoiceLines = true;
                this.ShowInvoiceLinesGrid = false;

                this.IsLoading = true;

                //consignmentId: number = undefined
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {

                        return this.salesOrderService.getSalesOrderListForCI(
                            this.ownerEntityId,
                            this.TypeId,
                            this.supplierModel.Id,
                            this.customerModel.Id,
                            this.consigneeModel.Id,
                            this.routeModel == undefined ? 0 : this.routeModel.Id,
                            this.consignmentId
                        ).query((data) => {

                            this.gSOList.data = data;
                            this.IsLoading = false;


                            for (let item of this.gSOList.data) {
                                if ((item as interfaces.sales.ISalesOrderForCommercialInvoice).IsSelected) {
                                    this.selectedSO = item;
                                    this.$timeout(() => {
                                        this.apiSOList.selection.unSelectRow(item);
                                        this.apiSOList.selection.selectRow(item);
                                    });
                                }
                            }

                            this.DisableNextButton = false;

                            if (this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                                this.DisableNextButton = true;
                            }

                            if (!this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                                this.DisableNextButton = false;
                                this.NextStepButton = "Submit";
                            }


                        },
                            (errorResponse) => {

                                this.generalService.displayMessageHandler(errorResponse.data);
                                this.IsLoading = false;
                            }).$promise;
                    });
                });

            }

            //Load Invoice Lines
            LoadIL() {

                if (this.selectedSO && this.apiSOList.selection.getSelectedRows().length > 0) {
                    this.orderIdList = _.map(this.apiSOList.selection.getSelectedRows(), (o) => {
                        return {
                            SalesOrderId: o.SalesOrderId,
                            CommercialInvoiceId: this.commercialInvoiceId > 0 ? this.commercialInvoiceId : undefined
                        };
                    });
                }

                this.IsLoading = true;
                this.$timeout(() => {

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.commercialInvoiceService.GetSalesOrderAllocationLines().query({},

                            this.orderIdList, (data) => {

                                this.gvwILList.data = data;

                                this.invoiceLinesList = data;

                                this.IsLoading = false;
                            },
                            (errorResponse) => {

                                this.generalService.displayMessageHandler(errorResponse.data);
                                this.IsLoading = false;

                            }).$promise;
                    });
                });
            }

            SubmitInvoice() {
                var selectedSalesOrderLines: interfaces.logistics.ICISalesOrderLineSubmit[] = [];
                var orderIdList: interfaces.logistics.ICISalesOrderSubmit[] = [];

                var salesOrderWithAllocationLineSubmit: interfaces.logistics.ICISalesOrderWithAllocationLineSubmit = {
                    selectedSalesOrderLines: [],
                    orderIdList: []
                };

                salesOrderWithAllocationLineSubmit.selectedSalesOrderLines = selectedSalesOrderLines;
                salesOrderWithAllocationLineSubmit.orderIdList = orderIdList;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    if (this.consignmentId > 0 && this.commercialInvoiceId === 0) {
                        return this.CreateCommercialInvoiceFromSalesOrder(salesOrderWithAllocationLineSubmit);
                    }
                    else {
                        return this.UpdateFromSalesOrder(salesOrderWithAllocationLineSubmit);
                    }
                });
            }

            SubmitInvoiceLines() {

                var selectedSalesOrderLines: interfaces.logistics.ICISalesOrderLineSubmit[] = [];
                var orderIdList: interfaces.logistics.ICISalesOrderSubmit[] = [];

                var salesOrderWithAllocationLineSubmit: interfaces.logistics.ICISalesOrderWithAllocationLineSubmit = {
                    selectedSalesOrderLines: [],
                    orderIdList: []
                };

                orderIdList = this.orderIdList;

                //Only send rows where InvoiceQuantity != AvailableQuantity
                selectedSalesOrderLines = _.map(this.gvwILList.data, (n) => {
                    if (n.InvoiceQuantity != n.AvailableQuantity) return {
                        SkuLineId: n.SkuLineId,
                        CommercialInvoiceId: n.CommercialInvoiceId,
                        LineType: n.LineType,
                        CISKUId: n.CISKUId,
                        OrderQuantity: n.InvoiceQuantity
                    };
                });



                salesOrderWithAllocationLineSubmit.selectedSalesOrderLines = selectedSalesOrderLines.filter(n => n); //added this filter hack to remove any undefined rows from the array [VH]
                salesOrderWithAllocationLineSubmit.orderIdList = orderIdList;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    if (this.consignmentId === 0) {
                        return this.CreateFromSalesOrder(salesOrderWithAllocationLineSubmit);

                    } else if (this.consignmentId > 0 && this.commercialInvoiceId === 0) {
                        return this.CreateCommercialInvoiceFromSalesOrder(salesOrderWithAllocationLineSubmit);
                    }
                    else {
                        return this.UpdateFromSalesOrder(salesOrderWithAllocationLineSubmit);
                    }
                });

            }

            CreateFromSalesOrder(salesOrderWithAllocationLineSubmit: interfaces.logistics.ICISalesOrderWithAllocationLineSubmit) {
                return this.consignmentService.CreateFromSalesOrder(

                    this.ownerEntityId,
                    this.TypeId,
                    this.shipperModel.Id,
                    this.consigneeModel.Id,
                    this.supplierModel.Id,
                    this.customerModel.Id,
                    this.customNumber ? this.customNumber : undefined,
                    this.consignmentCounterId ? this.consignmentCounterId : undefined,
                    this.invoiceCustomNumber ? this.invoiceCustomNumber : undefined,
                    this.invoiceCounterId ? this.invoiceCounterId : undefined,
                    this.routeModel ? this.routeModel.Id : undefined,

                ).save(salesOrderWithAllocationLineSubmit, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        if (this.isInbound)
                            this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                        else
                            this.$state.go("auth.ConsignmentExports.Update", { conId: Number(result.ID) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.disableSalesOrders = true;
                    this.disableInvoiceLines = true;

                    this.IsLoading = false;

                }).$promise;
            }

            CreateCommercialInvoiceFromSalesOrder(salesOrderWithAllocationLineSubmit: interfaces.logistics.ICISalesOrderWithAllocationLineSubmit) {
                return this.consignmentService.CreateCommercialInvoiceFromSalesOrder(
                    this.consignmentId, this.supplierModel.Id, this.customerModel.Id,
                    this.invoiceCustomNumber ? this.invoiceCustomNumber : undefined,
                    this.invoiceCounterId ? this.invoiceCounterId : undefined
                ).save(salesOrderWithAllocationLineSubmit, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {


                        if (this.$stateParams.manId) {
                            if (this.isInbound)
                                this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice", { conId: Number(result.ID) });
                            else
                                this.$state.go("auth.Manifests.Update.ConsignmentExport.CommercialInvoice", { comId: Number(result.ID) });
                        }
                        else {
                            if (this.isInbound)
                                this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                            else
                                this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice", { comId: Number(result.ID) });
                        }

                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.disableSalesOrders = false;
                    this.disableInvoiceLines = false;

                    this.IsLoading = false;

                }).$promise;
            }

            UpdateFromSalesOrder(salesOrderWithAllocationLineSubmit: interfaces.logistics.ICISalesOrderWithAllocationLineSubmit) {
                return this.consignmentService.UpdateFromSalesOrder(
                    this.consignmentId
                ).save(salesOrderWithAllocationLineSubmit, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        /*if (this.isInbound)
                             this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                         else
                             this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice", {  comId: Number(result.ID) });*/


                        if (this.$stateParams.manId) {
                            if (this.isInbound)
                                this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice", { conId: Number(result.ID) });
                            else
                                this.$state.go("auth.Manifests.Update.ConsignmentExport.CommercialInvoice", { comId: Number(result.ID) });
                        }
                        else {
                            if (this.isInbound)
                                this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                            else
                                this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice", { comId: Number(result.ID) });
                        }

                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.disableSalesOrders = false;
                    this.disableInvoiceLines = false;

                    this.IsLoading = false;

                }).$promise;
            }


            TabSelected() {
                if (this.selectedTab == 0) {
                    this.NextStepButton = "Next";
                }
                else {
                    this.NextStepButton = "Submit";
                }
            }

            NextStep() {
                this.selectedTab++;

                if (this.selectedTab == 1) {

                    this.disableSalesOrders = false;
                    this.ShowSalesOrdersGrid = true;
                    this.DisableNextButton = true;
                    this.LoadSO();


                } else if (this.selectedTab == 2 && this.ShowSalesOrdersGrid) {


                    if (!this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                        this.disableConsignment = true;
                        this.disableSalesOrders = true;
                        this.disableInvoiceLines = true;

                        this.IsLoading = true;

                        this.SubmitInvoice();
                    }
                    else {

                        this.disableInvoiceLines = false;
                        this.ShowInvoiceLinesGrid = true;
                        this.NextStepButton = "Submit";

                        this.LoadIL();
                    }

                } else if (this.selectedTab == 3 && this.ShowInvoiceLinesGrid) {

                    this.disableConsignment = true;
                    this.disableSalesOrders = true;
                    this.disableInvoiceLines = true;

                    this.IsLoading = true;

                    this.SubmitInvoiceLines();
                }
            }

            //Navigation
            close() {
                if (this.consignmentId != 0) {
                    if (this.isInbound)
                        this.$state.go("auth.ConsignmentImports.Update", { conId: Number(this.consignmentId) });
                    else
                        this.$state.go("auth.ConsignmentExports.Update", { conId: Number(this.consignmentId) });
                }
                else this.$state.go("^");
            }
        }

        angular.module("app").controller("consignmentCreateSalesOrderCtrl", controllers.consigment.consignmentCreateSalesOrderCtrl);
    }
}